import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import home9 from './Component//home/home9.png';
import home3 from './Component/home//home2.jpg';
import home5 from './Component/home//home6.jpg';
import home6 from './Component/home//home7.jpg';
import home4 from './Component/home3.jpg';
import brand1 from './Component/home/brand1.png';
import brand2 from './Component/home/brand2.png';
import i1 from './Component/i1.png';
import carpet from './Component/product/h1.png';
import hardwood from './Component/product/h2.png';
import laminate from './Component/product/h3.png';
import Tile from './Component/product/h4.png';
import new1 from './Component/new1.jpg';
import new2 from './Component/new2.jpg';
import homenewCheif from './Component/homenewCheif.png';

function Home() {
	// const t = "LM12";

	return (
		<div >
			

			<Headers />
			{/* <div> <a href='/contact'> <img src= {require('./Component/photo/' + t + '.png')} alt="Los Angeles" class="d-block img-fluid" /></a></div> */}
			<section>
				<div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
					<div class="carousel-inner">
						
						<div class="carousel-item active" data-bs-interval="3000">
						<a href='/free-estimate'>
							<img
								src={home3}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto "
							/>
							</a>
						</div>
						<div class="carousel-item active" data-bs-interval="3000">
						<a href='tel:+14173427662'>
							<img
								src={homenewCheif}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto "
							/>
							</a>
						</div>
						<div class="carousel-item flex" data-bs-interval="3000">
						<a href='tel:+14173120044' className='flex-col'>
							<img
								src={new1}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto"
							/>
							</a>
							<a href='tel:+14173427662' className='flex-col'>
							<img
								src={new2}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto"
							/>
							</a>
						</div>
					</div>
					<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Previous</span>
					</button>
					<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="visually-hidden">Next</span>
					</button>
				</div>
				
			</section>

			<section className='  p-4' >
				<div className='container text-center'>
					<h1 class=" text-3xl   leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl "> Looking for new floors in the Cassville, MO area?</h1>
					<p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400"> SHOP OUR EXTENSIVE FLOORING CATALOG</p>
					<div class="row flex justify-content-center ">
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<img src={carpet} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/carpet'> <div className='btn btn-danger w-100'> Carpet</div> </a>
						</div>
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<img src={hardwood} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/hardwood'> <div className='btn btn-danger w-100'>Hardwood</div> </a>
						</div>
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<img src={laminate} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/luxuryvinyl'> <div className='btn btn-danger w-100'>Luxury-vinyl</div> </a>
						</div>
						<div class="col-md-3 col-lg-3 col-6 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<img src={Tile} alt="Los Angeles" class=" w-100 h-75 rounded object-cover object-center" />
							<a href='/products/Tile'> <div className='btn btn-danger w-100'> Tile</div> </a>
						</div>

					</div>
				</div>



			</section>
			<a href='/free-estimate'>
							<img
								src={home9}
								alt="Flooring, furniture and hardware needs"
								className="mx-auto "
							/>
							</a>
			<section >
				

			</section>

			<section class="p-4 " style={{backgroundColor:'#803333'}}>
				<div className='container'>
					<div class="row flex content-center">
						<div class="col-md-3 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%', borderBlockColor: '#803333' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Reviews</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c px-1 ">
										The Cassville, MO area trusts Nickles Flooring for all of their flooring needs.
									</p>
									<a href='/reviews'> <div className='btn btn-danger w-100'> Our Reviews</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-3 col-sm-6 col-6 p-1 col-12" >
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Request</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c lg:px-5 ">
										Request your accurate and professional in-home flooring estimate.
									</p>
									<div class="mt-auto">
										<a href='/free-estimate' class='btn btn-danger w-100'>Schedule Now</a>
									</div>								</div>
							</div>

						</div>
						<div class="col-md-3 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Services</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c ">
										Our professional flooring installers are ready to help you with your next project.
									</p>
									<a href='/flooring-services'> <div className='btn btn-danger w-100'> what we Offer</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-3 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" text-center"> Financing</h2>
									</div>
								</div>
								<div class="card-body-c align-self-center">
									<p class="content-c ">
										Get your dream floors now, pay over time with our convenient financing options, subject to approved credit.
									</p>
									<a href='/financing'> <div className='btn btn-danger w-100'>Learn more</div> </a>
								</div>
							</div>

						</div>
					</div>
				</div>

			</section>

			<section className='container'>
				<div class="row p-3" >

					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class="lead font-bold">Serving you and your home since 34 years!</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
										<div class="card-body-c align-self-center">
											<p class="content-c">Being a family-owned and operated business, we’re able to offer you that personal touch you’ve been looking for. Our goal is to always make all of our customers happy, and we believe in treating each customer like a part of our family.</p>
											<p class="content-c">We look forward to hearing from you soon! And when you’re in Cassville we hope you come in and visit.</p>
											<p class="content-c">Builders & Contractors: We have a special program in place for your new home construction and full home remodels. Call us today for details</p>
											<p class="lead">
												<a href="/about-us" class="btn btn-danger btn-lg active" role="button" style={{borderRadius: '8px' }}>Explore More About Us</a>
											</p>

										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
						<img src={home5} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
					</div>
				</div>
			</section >
			<section className='container'>
				<div class="row p-3" >
				<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
						<img src={home6} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class=" font-semibold text-2xl">Why should you choose us?</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo  w-full" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
									<div class="card-body-c align-self-center">
											<p class="content-c py-3"><ul>
												<li className='py-2'>In-House Design Consultants</li>
												<li className='py-2'>Commitment to Excellence</li>
												<li className='py-2'>Family Owned & Operated</li>
												<li className='py-2'>Knowledgeable Staff</li>
												<li className='py-2'>Buying Power</li>
												<li className='py-2'>Large Selection of In-stock Products Available</li>
											</ul></p>
											<p class="lead">
												<a href="/free-estimate" class="btn btn-danger btn-lg active" role="button" style={{ borderRadius: '8px' }}>Request an Estimate</a>
											</p>
										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
					
				</div>
			</section >
			<section className='container p-5'>
				<div class="row rounded">
					<div class="col-lg-4 col-md-12 mb-4 mb-lg-0  text-white rounded" style={{ backgroundColor: '#803333' }}>
						<p className='text-center ' style={{ paddingTop: '80px' }}> <h1>Get inspired</h1></p>
						<p className='text-center'>We've got you covered from:
							flooring, carpet, hardwood, waterproof hardwood, plank, tile, flooring supplies, flooring tools, custom showers, backsplashes, sinks, faucets, & vanity tops.</p>
						<a href="/inspiration" className='btn btn-danger w-full p-2'> View our Galleries</a>
					</div>

					<div class="col-lg-8 mb-8 mb-lg-0">
						<img src={i1} alt="Los Angeles" class="d-block rounded" />


					</div>


				</div>
			</section>
			<div><Request /></div>
			<section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
					
				</div>
				<section>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
						<div >
							<img
								src={brand2}
								alt="brands"
								className="mx-auto"
							/>
						</div>
			</section>

			</section>
			<div><Footer /></div>


		</div>
	);
}

export default Home;
