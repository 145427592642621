import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import home2 from './Component/s3.png';
import s2 from './Component/s2.png';

function About() {
	return (
		<div>
			<div><Headers /> </div>
			<div className='container'>
				<div className='text-center font-bold p-4'>
					<span style={{ fontSize: '35px' }}>Services</span> <br />
					<small className='justify'> SEE WHY WE’RE THE CASSVILLE, MO AREA'S MOST TRUSTED SERVICE PROVIDER</small>
				</div>
				<section >
					<div class="row p-3" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<img src={home2} alt="Los Angeles" class="d-block rounded" />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<ul className='jumbotron' >
							
								<li class=" font-bold text-slate-500 py-2">Chief construction management</li>
								<li class=" font-bold text-slate-500 py-2">Bathroom remodeling <a href='/bathroom-remodeling' style={{ color: '#803333' }}>Learn more </a></li>
								<li class=" font-bold text-slate-500 py-2">Blinds & shutters <a href='/blinds-n-shutters' style={{ color: '#803333' }}>Learn more </a></li>
								<li class=" font-bold text-slate-500 py-2">Shaw products <a href='https://shawfloors.com/' style={{ color: '#803333' }}>Learn more </a></li>
								<li class=" font-bold text-slate-500 py-2">Builder & contractor program <a href='/builder-n-contractor-program' style={{ color: '#803333' }}>Learn more </a></li>
								<li class=" font-bold text-slate-500 py-2">Design Services <a href='/design-service' style={{ color: '#803333' }}>Learn more </a></li>
								<li class=" font-bold text-slate-500 py-2">Onyx showers & vanities</li>
								<li class=" font-bold text-slate-500 py-2">Certified waterproof custom showers</li>
								<li class=" font-bold text-slate-500 py-2">Sinks & faucets</li>
								<li class=" font-bold text-slate-500 py-2">Design consultation</li>
								<li class=" font-bold text-slate-500 py-2">Custom area rugs</li>
								<li class=" font-bold text-slate-500 py-2">Installation</li>
								<li class=" font-bold text-slate-500 py-2">Financing</li>
								<li class="">
									<a href="/free-estimate" class="btn btn-danger btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
								</li>
							</ul>

						</div>
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 py-5">
							<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>
						</div>
					</div>
				</section >
			</div>
			<div><Footer /> </div>
		</div>
	)
}

export default About
